import { components } from '@/lib/helpers.js'
import playerTracker from './lib/player-tracker.js'

//
// Fonts
//
import '@fontsource/open-sans/800.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/400.css'

//
// Styles
//
import '../css/style.css'

//
// Initialize components
//
components({
    header: import('@/components/header.js'),
    'embed-video': import('@/components/embed/video.js'),
    'dropdown-toggler': import('@/components/dropdown.js'),
    'tabs-content': import('@/components/tabs.js'),
    'slider-component': import('@/components/slider.js'),
    'book-button': import('@/components/book-button.js'),
})

// Search focus
let searchToggler = document.getElementById('search-toggler')
searchToggler.addEventListener('click', (e) => {
    setTimeout(() => {
        document.getElementById('search-input').focus()
    }, 500)
})

// Smooth scroll to anchor
if (location.hash) {
    let target = document.querySelector(location.hash)
    if (target) {
        setTimeout(() => {
            target.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            })
        }, 1)
    }
}

//
// Track WP video player
//
playerTracker('video.wp-video-shortcode')

//
// Fancybox
//
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
Fancybox.bind('article .gallery a', {
    groupAll: true,
})