import { push } from '@/lib/dataLayer.js'

export default function playerTracker(selector) {
    let currentStep = 0
    const steps = [10, 25, 50, 75]
    const players = [...document.querySelectorAll(selector)]
    const getPlayerState = (eventName, { target }) => {
        const { currentTime, duration, src } = target
        const videoCurrentTime = parseInt(currentTime)
        const videoDuration = parseInt(duration)
        const progress = videoCurrentTime / videoDuration
        const percentage = parseInt(progress * 100)

        return {
            event: eventName,
            'gtm.videoCurrentTime': videoCurrentTime,
            'gtm.videoDuration': videoDuration,
            'gtm.videoPercent': percentage,
            'gtm.videoProvider': 'html5',
            'gtm.videoUrl': src,
        }
    }
    const onPlay = (event) => {
        push(getPlayerState('video_start', event))
        event.currentTarget.removeEventListener('play', onPlay, false)
    }
    const onProgress = (event) => {
        const eventData = getPlayerState('video_progress', event)
        const nextStep = steps.find((step) => step > currentStep)

        if (!nextStep) {
            event.currentTarget.removeEventListener(
                'timeupdate',
                onProgress,
                false
            )
        } else if (eventData['gtm.videoPercent'] >= nextStep) {
            currentStep = nextStep
            eventData['gtm.videoPercent'] = nextStep
            push(eventData)
        }
    }
    const onEnd = (event) => {
        push(getPlayerState('video_complete', event))
        event.currentTarget.removeEventListener('ended', onEnd, false)
    }

    players.forEach((player) => {
        player.addEventListener('play', onPlay, false)
        player.addEventListener('timeupdate', onProgress, false)
        player.addEventListener('ended', onEnd, false)
    })
}
