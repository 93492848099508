export function components(components = {}) {
    const els = [...document.querySelectorAll('[data-component]')]
    const resolveComponent = (component) => {
        if (typeof component === 'function') {
            return new Promise((resolve) => resolve({ default: component }))
        }

        return component
    }

    els.forEach((el) => {
        const name = el.dataset.component
        const component = components[name]

        if (!component) {
            console.warn(`Component ${name} not available`)

            return
        }

        resolveComponent(component).then(({ default: component }) =>
            component({ el })
        )
    })
}

export function translate(key) {
    return window.translations?.[key] ?? key
}
